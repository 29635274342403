import React from 'react';
import Footer from '../components/footer';
import { TextWrapper } from '../components/TextWrapper';
import { useLanguage } from '../contexts/LanguageContext';

export const DataProtection: React.FC = () => {
  const { translations } = useLanguage();
  const { legal: { dataProtection } } = translations;

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-6 py-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-12 text-center">
          <TextWrapper>{dataProtection.title}</TextWrapper>
        </h1>

        {/* GDPR Compliance */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            <TextWrapper>{dataProtection.compliance.title}</TextWrapper>
          </h2>
          <p className="text-gray-600">
            <TextWrapper>{dataProtection.compliance.text}</TextWrapper>
          </p>
        </section>

        {/* Security Measures */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            <TextWrapper>{dataProtection.measures.title}</TextWrapper>
          </h2>
          <ul className="list-disc pl-6 space-y-2 text-gray-600">
            {dataProtection.measures.items.map((item, index) => (
              <li key={index}><TextWrapper>{item}</TextWrapper></li>
            ))}
          </ul>
        </section>

        {/* Automated Channels */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            <TextWrapper>{dataProtection.channels.title}</TextWrapper>
          </h2>
          <ul className="list-disc pl-6 space-y-2 text-gray-600">
            {dataProtection.channels.items.map((item, index) => (
              <li key={index}><TextWrapper>{item}</TextWrapper></li>
            ))}
          </ul>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default DataProtection; 