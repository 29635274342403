import React, { createContext, useContext, useEffect, useState } from 'react';
import en from '../locales/en.json';
import fr from '../locales/fr.json';

type Translations = {
  home: {
    hero: {
      title: string;
      subtitle: string;
      description: string;
      cta: {
        demo: {
          title: string;
          url: string;
        };
        learn: {
          title: string;
          url: string;
        };
      };
    };
  };
  nav: {
    about: string;
    tagline: {
      main: string;
      sub: string;
    };
  };
  about: {
    intro: {
      title: string;
      subtitle: string;
    };
    vision: {
      title: string;
      text: string;
    };
    solution: {
      title: string;
      text: string;
    };
    philosophy: {
      title: string;
      text: string;
    };
    start: {
      title: string;
    };
    teams: {
      title: string;
      members: {
        Chloé: {
          name: string;
          position: string;
          description: string;
          image: string;
          linkedin: string;
        };
        Marine: {
          name: string;
          position: string;
          description: string;
          image: string;
          linkedin: string;
        };
      };
    };
  };
  legal: {
    privacy: {
      title: string;
      company: {
        title: string;
        details: {
          name: string;
          type: string;
          address: string;
          capital: string;
          siret: string;
          vat: string;
          contact: string;
          director: string;
        };
        hosting: {
          title: string;
          address: string;
          phone: string;
          website: string;
        };
      };
      collect: {
        title: string;
        items: string[];
      };
      use: {
        title: string;
        items: string[];
      };
      retention: {
        title: string;
        items: string[];
      };
    };
    terms: {
      title: string;
      acceptance: {
        title: string;
        text: string;
      };
      license: {
        title: string;
        intro: string;
        items: string[];
      };
      modifications: {
        title: string;
        text: string;
      };
      contact: {
        text: string;
        email: string;
      };
    };
    dataProtection: {
      title: string;
      compliance: {
        title: string;
        text: string;
      };
      measures: {
        title: string;
        items: string[];
      };
      channels: {
        title: string;
        items: string[];
      };
    };
  };
  footer: {
    solutions: {
      title: string;
      lenny: string;
    };
    company: {
      title: string;
      about: string;
      contact: string;
      demo: string;
    };
    legal: {
      title: string;
      privacy: string;
      terms: string;
      data: string;
    };
    copyright: string;
  };
};

type LanguageContextType = {
  language: 'en' | 'fr';
  translations: Translations;
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<'en' | 'fr'>('fr');
  const [translations, setTranslations] = useState<Translations>(fr);

  useEffect(() => {
    // Get browser language
    const browserLang = navigator.language.split('-')[0];
    const newLang = browserLang === 'fr' ? 'fr' : 'en';
    setLanguage(newLang);
    setTranslations(newLang === 'fr' ? fr : en);
  }, []);

  return (
    <LanguageContext.Provider value={{ language, translations }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}; 