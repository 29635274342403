import React from 'react';
import Footer from '../components/footer';
import { TextWrapper } from '../components/TextWrapper';
import { useLanguage } from '../contexts/LanguageContext';

export const About: React.FC = () => {
  const { translations } = useLanguage();
  const { about } = translations;

  return (
    <div className="min-h-screen bg-background">
      {/* Main Content Section */}
      <div className="pt-40">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl sm:text-5xl font-bold text-content mb-16 text-center">
            <TextWrapper>{about.intro.title}</TextWrapper>
          </h1>

          {/* Vision Section */}
          <div className="mb-24 text-center">
            <blockquote className="relative">
              <div className="absolute -top-6 left-0 text-primary text-9xl opacity-20">"</div>
              <p className="relative text-2xl sm:text-3xl font-light text-content/90 italic max-w-3xl mx-auto leading-relaxed">
                <TextWrapper>{about.intro.subtitle}</TextWrapper>
              </p>
            </blockquote>
          </div>
          
          <div className="prose prose-lg max-w-none text-content/80 space-y-16">
            {/* Notre Vision */}
            <div>
              <h3 className="text-2xl font-medium text-content/90 mb-6"><TextWrapper>{about.vision.title}</TextWrapper></h3>
              <p className="text-xl leading-relaxed">
                <TextWrapper>{about.vision.text}</TextWrapper>
              </p>
            </div>
            
            {/* Notre Solution */}
            <div>
              <h3 className="text-2xl font-medium text-content/90 mb-6"><TextWrapper>{about.solution.title}</TextWrapper></h3>
              <div className="pl-6 border-l-4 border-primary/20">
                <p className="text-xl leading-relaxed">
                  <TextWrapper>{about.solution.text}</TextWrapper>
                </p>
                <p className="text-lg text-primary font-medium mt-4">
                  
                </p>
              </div>
            </div>
            
            {/* Notre Philosophie */}
            <div>
              <h3 className="text-2xl font-medium text-content/90 mb-6"><TextWrapper>{about.philosophy.title}</TextWrapper></h3>
              <p className="text-xl leading-relaxed">
                <TextWrapper>{about.philosophy.text}</TextWrapper>
              </p>
            </div>
            
            <div className="pt-8">
              <p className="text-2xl font-medium text-primary text-center">
                <TextWrapper>{about.start.title}</TextWrapper>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Team Section */}
      <div className="py-32 bg-background/50 mt-20 ">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 ">
          <h2 className="text-3xl font-bold text-content text-center mb-20 ">
            <TextWrapper>{about.teams.title}</TextWrapper>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-32 max-w-4xl mx-auto">
            {Object.entries(about.teams.members).map(([key, member]) => (
              <div key={key} className="flex flex-col items-center text-center">
                <a 
                  href={member.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cursor-pointer"
                >
                  <div className="w-96 h-96 mb-8 rounded-full overflow-hidden shadow-lg hover:shadow-xl transition-shadow">
                    <img
                      src={member.image}
                      alt={member.name}
                      className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                    />
                  </div>
                </a>
                <h3 className="text-2xl font-semibold text-content mb-2">
                  <TextWrapper>{member.name}</TextWrapper>
                </h3>
                <p className="text-primary font-medium mb-6">
                  <TextWrapper>{member.position}</TextWrapper>
                </p>
                <p className="text-content/70 leading-relaxed max-w-sm">
                  <TextWrapper>{member.description}</TextWrapper>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About; 