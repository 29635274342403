import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/navbar';
import { LanguageProvider } from './contexts/LanguageContext';
import About from './pages/about';
import { DataProtection } from './pages/dataProtection';
import Home from './pages/home';
import { PrivacyPolicy } from './pages/privacyPolicy';
import { TermsOfUse } from './pages/termsOfUse';

const App: React.FC = () => {
  return (
    <LanguageProvider>
      <Router>
        <div className="min-h-screen">
          <Navbar />
          <main className="pt-20">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfUse />} />
              <Route path="/data-protection" element={<DataProtection />} />
            </Routes>
          </main>
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default App; 