import React from 'react';

interface TextWrapperProps {
  children: string;
  isPrimaryContent?: boolean;
}

export const TextWrapper: React.FC<TextWrapperProps> = ({ children, isPrimaryContent = false }) => {
  const parts = children.split(/(Lenny)/gi);
  
  return (
    <span>
      {parts.map((part, i) => 
        part.toLowerCase() === 'lenny' ? (
          <span 
            key={i}
            className={`font-bold italic ${isPrimaryContent ? 'text-white' : 'text-primary'}`}
          >
            {part}
          </span>
        ) : (
          part
        )
      )}
    </span>
  );
}; 