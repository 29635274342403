import React from 'react';
import Footer from '../components/footer';
import { TextWrapper } from '../components/TextWrapper';
import { useLanguage } from '../contexts/LanguageContext';

export const PrivacyPolicy: React.FC = () => {
  const { translations } = useLanguage();
  const { legal: { privacy } } = translations;

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-6 py-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-12 text-center">
          <TextWrapper>{privacy.title}</TextWrapper>
        </h1>

        {/* Company Information */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            <TextWrapper>{privacy.company.title}</TextWrapper>
          </h2>
          <div className="space-y-2 text-gray-600">
            {Object.values(privacy.company.details).map((detail, index) => (
              <p key={index}><TextWrapper>{detail}</TextWrapper></p>
            ))}
          </div>
          <div className="mt-4 space-y-2 text-gray-600">
            <h3 className="font-semibold"><TextWrapper>{privacy.company.hosting.title}</TextWrapper></h3>
            <p><TextWrapper>{privacy.company.hosting.address}</TextWrapper></p>
            <p><TextWrapper>{privacy.company.hosting.phone}</TextWrapper></p>
            <p><TextWrapper>{privacy.company.hosting.website}</TextWrapper></p>
          </div>
        </section>

        {/* Data Collection */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            <TextWrapper>{privacy.collect.title}</TextWrapper>
          </h2>
          <ul className="list-disc pl-6 space-y-2 text-gray-600">
            {privacy.collect.items.map((item, index) => (
              <li key={index}><TextWrapper>{item}</TextWrapper></li>
            ))}
          </ul>
        </section>

        {/* Data Usage */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            <TextWrapper>{privacy.use.title}</TextWrapper>
          </h2>
          <ul className="list-disc pl-6 space-y-2 text-gray-600">
            {privacy.use.items.map((item, index) => (
              <li key={index}><TextWrapper>{item}</TextWrapper></li>
            ))}
          </ul>
        </section>

        {/* Data Retention */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            <TextWrapper>{privacy.retention.title}</TextWrapper>
          </h2>
          <ul className="list-disc pl-6 space-y-2 text-gray-600">
            {privacy.retention.items.map((item, index) => (
              <li key={index}><TextWrapper>{item}</TextWrapper></li>
            ))}
          </ul>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy; 