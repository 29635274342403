import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import { TextWrapper } from '../components/TextWrapper';
import { useLanguage } from '../contexts/LanguageContext';

export default function Home() {
  const { translations } = useLanguage();
  const { hero } = translations.home;

  return (
    <div className="min-h-screen bg-background">
      {/* Hero Section */}
      <section className="relative min-h-screen flex items-center">
        {/* Background Image - Moved above content for mobile */}
        <div className="absolute inset-0 md:w-1/2 md:left-auto">
          <div className="relative h-full">
            {/* Gradient Overlay - Enhanced for mobile */}
            <div className="absolute inset-0 bg-gradient-to-r from-background via-background/80 to-background/50 md:via-background/50 md:to-transparent z-10" />
            <div className="absolute inset-0 bg-gradient-to-t from-background via-background/80 to-transparent z-10" />
            
            {/* Image - Updated for better mobile display */}
            <img 
              src="agent-lenny-full.png" 
              alt="Agent Lenny" 
              className="absolute inset-0 h-full w-full object-cover object-center"
            />
          </div>
        </div>

        {/* Content - Added relative positioning and z-index */}
        <div className="container mx-auto px-4 relative z-20">
          <div className="max-w-3xl">
            <h1 className="text-5xl md:text-6xl font-bold text-content mb-6">
              <TextWrapper>{hero.title}</TextWrapper>
              <br />
              <span className="text-primary"><TextWrapper>{hero.subtitle}</TextWrapper></span>
            </h1>
            <p className="text-xl md:text-2xl text-content/70 mb-12 max-w-2xl">
              <TextWrapper>{hero.description}</TextWrapper>
            </p>
            <div className="flex gap-4">
              <a 
                href={hero.cta.demo.url}
                className="inline-flex items-center justify-center px-8 py-3 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors"
              >
                <TextWrapper isPrimaryContent>{hero.cta.demo.title}</TextWrapper>
              </a>
              <Link 
                to={hero.cta.learn.url}
                className="inline-flex items-center justify-center px-8 py-3 border-2 border-content/10 text-content rounded-lg hover:bg-content/5 transition-colors"
              >
                <TextWrapper>{hero.cta.learn.title}</TextWrapper>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
}
