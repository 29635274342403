const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(metric => {
        onPerfEntry(metric);
        // Send to Google Analytics
        window.gtag('event', 'web_vitals', {
          event_category: 'Web Vitals',
          event_label: metric.name,
          value: Math.round(metric.value * 1000),
          non_interaction: true,
        });
      });
      getFID(metric => {
        onPerfEntry(metric);
        window.gtag('event', 'web_vitals', {
          event_category: 'Web Vitals',
          event_label: metric.name,
          value: Math.round(metric.value * 1000),
          non_interaction: true,
        });
      });
      getFCP(metric => {
        onPerfEntry(metric);
        window.gtag('event', 'web_vitals', {
          event_category: 'Web Vitals',
          event_label: metric.name,
          value: Math.round(metric.value * 1000),
          non_interaction: true,
        });
      });
      getLCP(metric => {
        onPerfEntry(metric);
        window.gtag('event', 'web_vitals', {
          event_category: 'Web Vitals',
          event_label: metric.name,
          value: Math.round(metric.value * 1000),
          non_interaction: true,
        });
      });
      getTTFB(metric => {
        onPerfEntry(metric);
        window.gtag('event', 'web_vitals', {
          event_category: 'Web Vitals',
          event_label: metric.name,
          value: Math.round(metric.value * 1000),
          non_interaction: true,
        });
      });
    });
  }
};

export default reportWebVitals;
