import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const { translations } = useLanguage();
  const { nav } = translations;

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav 
      className={`fixed w-full top-0 z-50 transition-all duration-300 ${
        isScrolled 
          ? 'py-4 bg-background/80 backdrop-blur-lg shadow-sm' 
          : 'py-6 bg-transparent'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <Link 
            to="/" 
            className="flex items-center gap-2 md:gap-4 group"
          >
            <img 
              src="/logo.png" 
              alt="2AM" 
              className="h-16 md:h-24 w-auto transition-transform duration-300 group-hover:scale-105" 
            />
            <div className="hidden md:flex flex-col">
              <span className="text-lg font-medium leading-tight">{nav.tagline.main}</span>
              <span className="text-sm text-content/70">{nav.tagline.sub}</span>
            </div>
          </Link>

          {/* Navigation Items */}
          <div className="flex items-center gap-3 md:gap-8">
            <Link 
              to="/about" 
              className={`relative text-base md:text-lg transition-colors ${
                location.pathname === '/about'
                  ? 'text-content font-medium'
                  : 'text-content/70 hover:text-content'
              }`}
            >
              <span>{nav.about}</span>
              {location.pathname === '/about' && (
                <span className="absolute -bottom-1 left-0 w-full h-0.5 bg-primary rounded-full" />
              )}
            </Link>
            <a 
              href={translations.home.hero.cta.demo.url}
              className="relative overflow-hidden group bg-primary text-white px-4 md:px-6 py-2 md:py-2.5 text-sm md:text-base rounded-lg transition-all duration-300 hover:bg-primary/90"
            >
              <span className="relative z-10">{translations.home.hero.cta.demo.title}</span>
              <span className="absolute inset-0 w-full h-full bg-white/20 transform -translate-x-full group-hover:translate-x-0 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
