import React from 'react';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';

export default function Footer() {
  const { translations } = useLanguage();
  const { footer, nav } = translations;

  return (
    <footer className="bg-background py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row gap-12">
          {/* Left Side - Logo, Description, and Socials */}
          <div className="md:w-1/4 space-y-8">
            <div className="flex items-center gap-4 flex-wrap">
              <img src="/logo.png" alt="2AM" className="h-24 w-24" />
              <div className="flex flex-col">
                <span className="text-lg font-medium leading-tight mb-1">{nav.tagline.main}</span>
                <span className="text-sm text-content/70">{nav.tagline.sub}</span>
              </div>
            </div>
            <div className="flex gap-4">
              <a href="https://www.linkedin.com/company/2am-music/" className="text-content hover:text-primary">
                <FaLinkedin size={24} />
              </a>
              <a href="https://www.instagram.com/2am.music.ai/" className="text-content hover:text-primary">
                <FaInstagram size={24} />
              </a>
            </div>
            <p className="text-content/70 text-sm">{footer.copyright}</p>
          </div>

          {/* Right Side - Navigation Links */}
          <div className="md:w-3/4 grid grid-cols-2 md:grid-cols-4 gap-8">
            {/* Solutions */}
            <div>
              <h4 className="font-medium text-lg mb-4">{footer.solutions.title}</h4>
              <ul className="space-y-2">
                <li><Link to="/lenny" className="text-content/70 hover:text-content">{footer.solutions.lenny}</Link></li>
              </ul>
            </div>

            {/* Company */}
            <div>
              <h4 className="font-medium text-lg mb-4">{footer.company.title}</h4>
              <ul className="space-y-2">
                <li><Link to="/about" className="text-content/70 hover:text-content">{footer.company.about}</Link></li>
                <li><Link to="mailto:contact@2am-music.com" className="text-content/70 hover:text-content">{footer.company.contact}</Link></li>
                <li><Link to="mailto:marine@2am-music.com" className="text-content/70 hover:text-content">{footer.company.demo}</Link></li>
              </ul>
            </div>

            {/* Legal */}
            <div>
              <h4 className="font-medium text-lg mb-4">{footer.legal.title}</h4>
              <ul className="space-y-2">
                <li><Link to="/privacy" className="text-content/70 hover:text-content">{footer.legal.privacy}</Link></li>
                <li><Link to="/terms" className="text-content/70 hover:text-content">{footer.legal.terms}</Link></li>
                <li><Link to="/data-protection" className="text-content/70 hover:text-content">{footer.legal.data}</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
} 