import React from 'react';
import Footer from '../components/footer';
import { TextWrapper } from '../components/TextWrapper';
import { useLanguage } from '../contexts/LanguageContext';

export const TermsOfUse: React.FC = () => {
  const { translations } = useLanguage();
  const { legal: { terms } } = translations;

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-6 py-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-12 text-center">
          <TextWrapper>{terms.title}</TextWrapper>
        </h1>

        {/* Acceptance */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            <TextWrapper>{terms.acceptance.title}</TextWrapper>
          </h2>
          <p className="text-gray-600">
            <TextWrapper>{terms.acceptance.text}</TextWrapper>
          </p>
        </section>

        {/* License */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            <TextWrapper>{terms.license.title}</TextWrapper>
          </h2>
          <p className="text-gray-600 mb-4">
            <TextWrapper>{terms.license.intro}</TextWrapper>
          </p>
          <ul className="list-disc pl-6 space-y-2 text-gray-600">
            {terms.license.items.map((item: string, index: number) => (
              <li key={index}><TextWrapper>{item}</TextWrapper></li>
            ))}
          </ul>
        </section>

        {/* Modifications */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            <TextWrapper>{terms.modifications.title}</TextWrapper>
          </h2>
          <p className="text-gray-600">
            <TextWrapper>{terms.modifications.text}</TextWrapper>
          </p>
        </section>

        {/* Contact */}
        <section className="mb-12">
          <p className="text-gray-600">
            <TextWrapper>{terms.contact.text}</TextWrapper>{' '}
            <a href={`mailto:${terms.contact.email}`} className="text-blue-600 hover:text-blue-800">
              <TextWrapper>{terms.contact.email}</TextWrapper>
            </a>
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfUse; 